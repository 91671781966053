body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.highlight {
  background-color: rgba(255,255,0, .5);
}

.lyrics-row {
  line-height: 1.2em;
  min-height: 1.2em;
  width: 100%;
  margin-bottom: 0.25em;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  position: relative;
}

.note-chord,
.note-misc {
  /* font-weight: bold; */
  padding: 0 2px;
}

.note-chord {
  color: #888;
  background-color: rgba(255,255,0, .5);
}

.note-misc {
  color: #888;
  background-color: rgba(255,200,0, .5);
}

.note-lyrics {
}
